import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import QRCode from "react-qr-code"
import { useQueryParam, StringParam } from "use-query-params"

const BarCode = () => {
  const [url, setUrl] = useQueryParam("url", StringParam)
  const [names, setNames] = useQueryParam("names", StringParam)

  return (
    <Layout>
      <div className="w-full flex justify-center items-center flex-col pt-10">
        {url && <QRCode value={url} />}
        <div className="mt-2">
          {names &&
            names.split(",").map(name => (
              <p className="text-center" key={name}>
                {name}
              </p>
            ))}
        </div>
      </div>
    </Layout>
  )
}

export default BarCode
